import React from "react"
import { BsStarFill } from "react-icons/bs";
import { BsStarHalf } from "react-icons/bs";
import { BsStar } from "react-icons/bs";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Review = props => {
  return (
		<>
      <br />
      { Number(props.item.star) > 4.5 && Number(props.item.star) <= 5.0 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 4.0 && Number(props.item.star) <= 4.5 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarHalf /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 3.5 && Number(props.item.star) <= 4.0 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 3.0 && Number(props.item.star) <= 3.5 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarFill /><BsStarFill /><BsStarHalf /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 2.5 && Number(props.item.star) <= 3.0 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarFill /><BsStarFill /><BsStar /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 2.0 && Number(props.item.star) <= 2.5 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarFill /><BsStarHalf /><BsStar /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 1.5 && Number(props.item.star) <= 2.0 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarFill /><BsStar /><BsStar /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 1.0 && Number(props.item.star) <= 1.5 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStarHalf /><BsStar /><BsStar /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 0.5 && Number(props.item.star) <= 1.0 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarFill /><BsStar /><BsStar /><BsStar /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) > 0.0 && Number(props.item.star) <= 0.5 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStarHalf /><BsStar /><BsStar /><BsStar /><BsStar /></span>&nbsp;</>
      )}
      { Number(props.item.star) === 0.0 && (
         <>&nbsp;&nbsp;<span style={{fontSize:`0.7rem`, color: `#F0C000`}}><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></span>&nbsp;</>
      )}

      { props.item.review > 0 && props.item.reviewUrl !== '' && (
         <a href={props.item.reviewUrl}
         onClick={e => {
            trackCustomEvent({
              category: `${props.category}`,
              action: `${props.item.shop}`,
              label: `レビュー`,
              transport: 'beacon'
            })
          }}									 								
         >
            <span style={{fontSize:`0.6rem`, color: `#0783c2`}}>{props.item.review}件</span>
         </a>
      )}
      { props.item.review > 0 && props.item.reviewUrl === '' && (
         <span style={{fontSize:`0.6rem`}}>{props.item.review}件</span>
      )}
		</>
	)
}

export default Review
